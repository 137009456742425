import Component from "vue-class-component";
import { SelectComponent as CommonSelect } from "common-vuejs-form";

import { Field } from "../";
import { Prop } from "vue-property-decorator";

@Component({
	components: {
		Field,
	},
})
export class SelectComponent extends CommonSelect {
	@Prop({
		default: true,
	})
	private readonly nullOptions!: boolean;
}
