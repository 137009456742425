
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Node } from "@tiptap/core";
import { Editor, EditorContent } from "@tiptap/vue-2";
import Link from "@tiptap/extension-link";
import Blocquote from "@tiptap/extension-blockquote";
import Bold from "@tiptap/extension-bold";
import Heading from "@tiptap/extension-heading";
import Italic from "@tiptap/extension-italic";
import ListItem from "@tiptap/extension-list-item";
import OrderedList from "@tiptap/extension-ordered-list";
import Paragraph from "@tiptap/extension-paragraph";
import Strike from "@tiptap/extension-strike";
import Document from "@tiptap/extension-document";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import History from "@tiptap/extension-history";

import { Plugin, PluginKey, NodeSelection } from "prosemirror-state";

import { render } from "katex";

declare module "@tiptap/core" {
	interface Commands<ReturnType> {
		katex: {
			setFormula: (formula: string, forceAsk: boolean) => ReturnType;
		};
	}
}

@Component({
	components: {
		EditorContent,
	},
})
export default class EditorComponent extends Vue {
	@Prop()
	private readonly value!: string;

	private editor: Editor | null = null;

	private mounted() {
		const KatexNode = Node.create({
			name: "katex",

			priority: 100,
			group: "inline",
			inline: true,
			defining: true,
			selectable: false,
			atom: true,

			addCommands() {
				return {
					setFormula: (formulaParam, forceAsk = false) => ({
						commands,
						state,
					}) => {
						let formula = formulaParam;

						if (forceAsk || !formula || formula.length === 0) {
							formula = window.prompt("Formule", formula) || "";
						}

						if (!formula || formula.length === 0) {
							return false;
						}

						formula = formula
							.replaceAll("*", "\\times")
							.replaceAll("/", "\\over");

						return commands.insertContentAt(state.selection.to, {
							type: this.name,
							attrs: {
								formula: formula,
							},
						});
					},
				};
			},

			addAttributes() {
				return {
					formula: {
						parseHTML: element => element.getAttribute("data-formula"),
						renderHTML: attributes => {
							return { "data-formula": attributes.formula };
						},
					},
				};
			},

			parseHTML() {
				return [{ tag: "span[data-formula]" }];
			},

			renderHTML({ HTMLAttributes }) {
				const span = document.createElement("span");

				span.dataset["formula"] = HTMLAttributes["data-formula"];
				render(HTMLAttributes["data-formula"], span, {
					output: "mathml",
				});

				return span;
			},

			addProseMirrorPlugins() {
				return [
					new Plugin({
						key: new PluginKey("formulaHandleClick"),
						props: {
							handleClickOn: (view, pos, node, nodePos) => {
								if (node.type.name !== "katex") {
									return false;
								}

								view.dispatch(
									view.state.tr
										.setSelection(
											NodeSelection.create(view.state.doc, nodePos - 1)
										)
										.deleteSelection()
								);

								const resultSet = this.editor.commands.setFormula(
									node.attrs.formula as string,
									true
								);

								if (!resultSet) {
									this.editor.commands.insertContentAt(nodePos, {
										type: this.name,
										attrs: {
											formula: node.attrs.formula,
										},
									});
								}

								return resultSet;
							},
						},
					}),
				];
			},
		});

		this.editor = new Editor({
			extensions: [
				Blocquote,
				Bold,
				Color.configure({
					types: ["textStyle"],
				}),
				Document,
				Heading,
				History,
				Italic,
				KatexNode,
				Link,
				ListItem,
				OrderedList,
				Paragraph,
				Strike,
				Text,
				TextStyle,
			],
			content: this.value,
			onUpdate: () => {
				if (!this.editor) {
					return;
				}

				this.$emit("input", this.editor.getHTML());
			},
		});
	}

	@Watch("value")
	private updateEditorValue() {
		if (!this.editor) {
			return;
		}

		const html = this.editor.getHTML();

		if (html !== this.value) {
			this.editor.commands.setContent(this.value, false);
		}
	}

	private setLink() {
		if (!this.editor) {
			return;
		}

		const previousUrl = this.editor.getAttributes("link").href || "";

		if (previousUrl) {
			this.editor
				.chain()
				.focus()
				.extendMarkRange("link")
				.unsetLink()
				.run();
			return;
		}

		const url = window.prompt("URL", previousUrl);
		// cancelled
		if (url === null || url.length === 0) {
			return;
		}

		// empty
		if (url === "") {
			this.editor
				.chain()
				.focus()
				.extendMarkRange("link")
				.unsetLink()
				.run();

			return;
		}

		// update link
		this.editor
			.chain()
			.focus()
			.extendMarkRange("link")
			.setLink({ href: url })
			.run();
	}

	private beforeDestroy() {
		if (!this.editor) {
			return;
		}

		this.editor.destroy();
	}
}
