import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import {
	Input as InputComponent,
	Select as SelectComponent,
} from "@/components/form";
import { axios } from "@/apis";
import { Treatment, Response } from "@/apis/models";

@Component({
	components: {
		InputComponent,
		SelectComponent,
	},
})
export class StepTreatmentController extends Vue {
	@Prop({ required: true })
	private readonly value!: Treatment;

	private search: string | null = null;
	private treatments: Response<Treatment[]> | null = null;
	private queryingTreatments = false;

	private get maxPage() {
		if (!this.treatments || this.treatments.total === 0) {
			return 1;
		}

		return Math.ceil(this.treatments.total / this.treatments.perPage);
	}

	private get currentPage() {
		if (!this.treatments) {
			return 1;
		}

		return this.treatments.currentPage;
	}

	private set currentPage(newValue: number) {
		if (newValue < 1 || newValue > this.maxPage) {
			return;
		}

		this.refreshTreatments(newValue);
	}

	private async created() {
		await this.refreshTreatments(this.currentPage);
	}

	private toggleTreatment(treatment: Treatment) {
		if (this.value && this.value.id === treatment.id) {
			this.$emit("input", null);
		} else {
			this.$emit("input", treatment);
		}
	}

	@Watch("search")
	private async refreshTreatments(page: number) {
		const query = [];

		if (this.search) {
			query.push(`search=${this.search}`);
		}

		if (page) {
			query.push(`page=${page}`);
		}

		this.queryingTreatments = true;
		this.treatments = (await axios.get(`/treatments?${query.join("&")}`)).data;
		this.queryingTreatments = false;
	}
}
