export interface Response<T> {
	draw: number;
	currentPage: number;
	data: T[];
	// firstPageUrl: string;
	// from: number;
	// lastPage: 1;
	// links: PageLink[];
	// nextPageUrl: string;
	// path: string;
	perPage: number;
	// prevPageUrl: string;
	// to: number;
	total: number;
}

export interface Treatment {
	id: number;
	pharmacologicalClass: string;
	dci: string;
	routeOfAdministration: RouteOfAdministration;
	processOfAdministration?: ProcessOfAdministration;
	unit: Unit;
	formulaTheoricWeight: string;
	formulaDailyDose: string;
	comment?: string;
	dailyCommonPosology?: string;
	source?: string;
	minDailyPrescribedDosage?: number;
	maxDailyPrescribedDosage?: number;
	minDailyPrescribedWeightDosage?: number;
	maxDailyPrescribedWeightDosage?: number;
	minDailyAdjustedDosage?: number;
	maxDailyAdjustedDosage?: number;
	minDailyAdjustedWeightDosage?: number;
	maxDailyAdjustedWeightDosage?: number;
	alertRecommendation?: AlertRecommendation[];
	createAt: string;
	updateAt?: string;
}

export interface Bibliography {
	dci: string;
	sources: string[];
}

export enum Unit {
	MICROGRAM = "MICROGRAM",
	MILLIGRAM = "MILLIGRAM",
	GRAM = "GRAM",
	UI = "UI",
}

export enum RouteOfAdministration {
	INTRAVENOUS = "INTRAVENOUS",
	ORAL = "ORAL",
	SUBCUTANEOUS = "SUBCUTANEOUS",
	INTRAMUSCULAR = "INTRAMUSCULAR",
	INTRAVENOUS_INTRAMUSCULAR = "INTRAVENOUS_INTRAMUSCULAR",
}

export enum ProcessOfAdministration {
	CONTINUOUS = "CONTINUOUS",
	DISCONTINUOUS = "DISCONTINUOUS",
	MAINTENANCE_DOSE = "MAINTENANCE_DOSE",
	LOADING_DOSE = "LOADING_DOSE",
	PROPHYLAXIS = "PROPHYLAXIS",
	PREVENTIVE = "PREVENTIVE",
	CURATIVE = "CURATIVE",
	MAINTENANCE = "MAINTENANCE",
}

export enum AlertRecommendation {
	ALERT_KIDNEY_FUNCTION = "ALERT_KIDNEY_FUNCTION",
	ALERT_HEPATIC_FUNCTION = "ALERT_HEPATIC_FUNCTION",
	RECOMMENDATION_THERAPEUTIC_FOLLOW_UP_ADVISED = "RECOMMENDATION_THERAPEUTIC_FOLLOW_UP_ADVISED",
	RECOMMENDATION_THERAPEUTIC_FOLLOW_UP_RECOMMENDED = "RECOMMENDATION_THERAPEUTIC_FOLLOW_UP_RECOMMENDED",
}

export interface TreatmentParam {
	pharmacologicalClass: string;
	dci: string;
	routeOfAdministration: RouteOfAdministration;
	processOfAdministration?: ProcessOfAdministration;
	unit: Unit;
	formulaTheoricWeight: string;
	formulaDailyDose: string;
	comment?: string;
	dailyCommonPosology?: string;
	source?: string;
	minDailyPrescribedDosage?: number;
	maxDailyPrescribedDosage?: number;
	minDailyPrescribedWeightDosage?: number;
	maxDailyPrescribedWeightDosage?: number;
	minDailyAdjustedDosage?: number;
	maxDailyAdjustedDosage?: number;
	minDailyAdjustedWeightDosage?: number;
	maxDailyAdjustedWeightDosage?: number;
	alertRecommendation?: AlertRecommendation[];
}

export interface Page {
	id: number;
	link: string;
	contentFr?: string;
	contentEn?: string;
	draft: boolean;
	homeReplace: boolean;
	menuShow: boolean;
	menuLabelFr?: string;
	menuLabelEn?: string;
	menuIcon?: string;
	createAt: string;
	updateAt?: string;
}

export interface PageParam {
	link: string;
	contentFr?: string;
	contentEn?: string;
	draft: boolean;
	homeReplace: boolean;
	menuShow: boolean;
	menuLabelFr?: string;
	menuLabelEn?: string;
	menuIcon?: string;
}

export interface PageMenu {
	link: string;
	menuLabelFr?: string;
	menuLabelEn?: string;
	menuIcon?: string;
}
