import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";

import { Options } from "@/components/table/typings";
import { Table as TableComponent } from "@/components/table";

import { Page } from "@/apis/models";
import { axios } from "@/apis";

@Component({
	components: {
		TableComponent,
	},
})
export default class ListController extends Vue {
	@Ref()
	private readonly table!: TableComponent<Page>;

	private options: Options = {
		table: true,
		columns: [
			{
				name: this.$t("page.link") as string,
			},
			{
				name: this.$t("page.content") as string,
			},
			{
				name: this.$t("page.updatedAt") as string,
			},
			{
				name: " ",
			},
		],
	};

	private getData() {
		return axios.get("/pages").then(response => response.data);
	}
}
