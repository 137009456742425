import Vue from "vue";
import { VueConstructor } from "vue";

import router from "@/router";
import { axios } from "@/apis";
import { AxiosBasicCredentials } from "axios";

export class AuthHolder {
	credentials: AxiosBasicCredentials | null = null;

	get authenticated() {
		return (
			this.credentials && this.credentials.username && this.credentials.password
		);
	}

	async authenticate(username: string, password: string) {
		this.credentials = {
			username,
			password,
		};

		try {
			const response = await axios.get("/auth/check");

			if (response.status !== 200) {
				this.deauthenticate();
				throw "Authentication failed";
			}
		} catch (e) {
			this.deauthenticate();
			throw "Authentication failed";
		}
	}

	deauthenticate() {
		this.credentials = null;
	}
}

const authHolder = new AuthHolder();

Vue.use({
	install(constructor: VueConstructor) {
		Object.defineProperty(constructor.prototype, "$auth", {
			// tslint:disable-next-line
			get: function() {
				return this.$root.$options.auth;
			},
		});

		router.beforeEach((to, from, next) => {
			if (to.path === "/admin/authentication/logout") {
				authHolder.deauthenticate();
				router.push({ name: "home" });
				return;
			}

			if (
				!to.meta ||
				!to.meta.secured ||
				(to.meta.secured && authHolder.authenticated)
			) {
				next();
				return;
			}

			// On évite de retourner sur la page d'authentification si on est déjà dessus
			if (from.name !== "admin.authentication") {
				router.push({
					name: "admin.authentication",
					query: { from: to.fullPath },
				});
			}
		});

		axios.interceptors.request.use(request => {
			if (authHolder.credentials) {
				request.auth = authHolder.credentials;
			}

			return request;
		});

		axios.interceptors.response.use(
			response => response,
			error => {
				if (error.response && error.response.status === 401) {
					if (authHolder.authenticated) {
						authHolder.deauthenticate();
					}

					if (router.currentRoute.name !== "admin.authentication") {
						router.push({
							name: "admin.authentication",
							query: { from: router.currentRoute.fullPath },
						});
					}
				}

				return Promise.reject(error);
			}
		);
	},
});

export default authHolder;
