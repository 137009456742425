import Vue from "vue";
import i18n from "@/libs/i18n";

function filter(value: string) {
	if (value == null || Number.isNaN(value)) {
		return i18n.t("treatment.unit.no.value");
	}

	return i18n.t(`treatment.unit.${value}`);
}

export default {
	install() {
		Vue.filter("unit", filter);
	},
};

export { filter };
