import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import {
	FontAwesomeIcon,
	FontAwesomeLayers,
	FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";

import App from "@/App.vue";
import router from "@/router";

import i18n from "@/libs/i18n";
import auth from "@/libs/auth";

import NumberFilter from "@/filters/NumberFilter";
import DateFilter from "@/filters/DateFilter";
import UnitFilter from "@/filters/UnitFilter";

import { fr, en } from "@/i18n/globals.json";

Vue.config.productionTip = false;

library.add(fas);
library.add(far);

Vue.component("fa", FontAwesomeIcon);
Vue.component("fat", FontAwesomeLayersText);
Vue.component("fa-layers", FontAwesomeLayers);
Vue.use(NumberFilter);
Vue.use(DateFilter);
Vue.use(UnitFilter);

i18n.mergeLocaleMessage("fr", fr);
i18n.mergeLocaleMessage("en", en);

new Vue({
	i18n,
	router,
	auth,
	render: h => h(App),
}).$mount("#app");
