import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

let locale = navigator.language;

if (!locale) {
	locale = "fr";
} else if (locale.indexOf("-") !== -1) {
	locale = locale.split("-")[0];
}

export default new VueI18n({
	locale: locale,
	fallbackLocale: "fr",
	preserveDirectiveContent: true,
});
