import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Input as InputComponent } from "@/components/form";
import { Treatment } from "@/apis/models";
import { mathjs } from "@/libs/mathjs";

import {
	CalculatedData,
	MorphologicalData,
	StepsImc,
	ViewType,
} from "../typings";
import RowCalculatedData from "../vues/RowCalculatedData.vue";
import MessageWarningDosage from "../vues/MessageWarningDosage.vue";

@Component({
	components: {
		InputComponent,
		RowCalculatedData,
		MessageWarningDosage,
	},
})
export class StepCalculateController extends Vue {
	@Prop({ required: true })
	private readonly morphologicalData!: MorphologicalData;

	@Prop({ required: true })
	private readonly treatment!: Treatment;

	private dailyPrescribedDosage = 0;
	private dailyPrescribedWeightDosage = 0;
	private viewType: ViewType = "SIMPLE";
	private calculatedData: CalculatedData | null = null;

	private get stepsImc() {
		return Object.keys(StepsImc);
	}

	private get minDailyPrescribedDosage() {
		return this.treatment.minDailyPrescribedDosage || 0;
	}

	private get maxDailyPrescribedDosage() {
		return this.treatment.maxDailyPrescribedDosage || undefined;
	}

	private get minDailyAdjustedDosage() {
		return this.treatment.minDailyAdjustedDosage || 0;
	}

	private get maxDailyAdjustedDosage() {
		return this.treatment.maxDailyAdjustedDosage || undefined;
	}

	private get minDailyAdjustedWeightDosage() {
		return this.treatment.minDailyAdjustedWeightDosage || 0;
	}

	private get maxDailyAdjustedWeightDosage() {
		return this.treatment.maxDailyAdjustedWeightDosage || undefined;
	}

	private get dailyPrescribedDosageInput() {
		return this.dailyPrescribedDosage;
	}

	private set dailyPrescribedDosageInput(value: number) {
		this.dailyPrescribedDosage = value;

		if (this.morphologicalData.weight) {
			this.dailyPrescribedWeightDosage =
				this.dailyPrescribedDosage / this.morphologicalData.weight;
		}
	}

	private get dailyPrescribedWeightDosageInput() {
		return this.dailyPrescribedWeightDosage;
	}

	private set dailyPrescribedWeightDosageInput(value: number) {
		this.dailyPrescribedWeightDosage = value;

		if (this.morphologicalData.weight) {
			this.dailyPrescribedDosage =
				this.dailyPrescribedWeightDosage * this.morphologicalData.weight;
		}
	}

	private async created() {
		this.treatmentChanged();
	}

	@Watch("treatment", { deep: true })
	private treatmentChanged() {
		this.dailyPrescribedDosageInput =
			this.treatment.minDailyPrescribedDosage || 0;
		this.updateCalculatedData();
	}

	@Watch("dailyPrescribedDosage")
	@Watch("dailyPrescribedWeightDosage")
	@Watch("morphologicalData", { deep: true })
	private updateCalculatedData() {
		const calculatedData: CalculatedData = {
			poids: this.morphologicalData.weight,
			taille: this.morphologicalData.height,
			sexe: this.morphologicalData.gender,
			poidsIdeal: this.morphologicalData.perfectWeight,
			masseMaigre: this.morphologicalData.leanMass,
			poidsRealiste: this.morphologicalData.realistWeight,
			imc: this.morphologicalData.imc,
			doseJournalierePrescrite: this.dailyPrescribedDosage,
			dosePoidsJournalierePrescrite: this.dailyPrescribedWeightDosage,
		};

		if (
			this.dailyPrescribedDosage != null &&
			this.dailyPrescribedDosage != undefined
		) {
			try {
				calculatedData.poidsTheorique = mathjs
					.parse(this.treatment.formulaTheoricWeight)
					.evaluate(calculatedData);
				calculatedData.doseJournaliereAjustee = mathjs
					.parse(this.treatment.formulaDailyDose)
					.evaluate(calculatedData);
				calculatedData.dosePoidsJournaliereAjustee = mathjs
					.parse("doseJournaliereAjustee / poids")
					.evaluate(calculatedData);

				calculatedData.ecartAbsoluPosologie = mathjs
					.parse(
						"abs((doseJournalierePrescrite-doseJournaliereAjustee)/doseJournalierePrescrite)*100"
					)
					.evaluate(calculatedData);

				calculatedData.dosePrescriteJournaliere70kg = mathjs
					.parse("70 * dosePoidsJournalierePrescrite")
					.evaluate(calculatedData);
				calculatedData.doseAjusteeJournaliere70kg = mathjs
					.parse("70 * dosePoidsJournaliereAjustee")
					.evaluate(calculatedData);
			} catch (e) {
				/* NO OP*/
			}
		}

		this.calculatedData = calculatedData;
	}
}
