import Vue from "vue";
import Component from "vue-class-component";

import { Treatment } from "@/apis/models";

import StepMorphologicalData from "../vues/StepMorphologicalData.vue";
import StepTreatment from "../vues/StepTreatment.vue";
import StepCalculate from "../vues/StepCalculate.vue";
import { MorphologicalData } from "../typings";

@Component({
	components: {
		StepMorphologicalData,
		StepTreatment,
		StepCalculate,
	},
})
export class IndexController extends Vue {
	private morphologicalData: MorphologicalData = new MorphologicalData();
	private treatment: Treatment | null = null;
}
