import { render, staticRenderFns } from "./MessageWarningDosage.vue?vue&type=template&id=213d83a4&"
import script from "./MessageWarningDosage.vue?vue&type=script&lang=ts&"
export * from "./MessageWarningDosage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "../i18n/MessageWarningDosage.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fgitlab-runner%2Fbuilds%2FXd6d7-5Y%2F0%2Fadapt-obese%2Fapplication%2Ffrontend%2Fsrc%2Fviews%2Fpublic%2Fcalculator%2Fvues%2FMessageWarningDosage.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports