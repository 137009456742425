import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { Params, Column, Filter, Options, DataGetter } from "../typings";

import { Input as InputComponent } from "@/components/form";

/**
 * Cette classe a pour but d'intégrer la logique permettant de gérer facilement l'affichage d'un ensemble d'élément
 * soit sous forme de tableau, soit sous une autre forme.
 * Elle permet de gérer la pagination, les filtres et les tris.
 *
 * @class
 */
@Component({
	components: {
		InputComponent,
	},
})
export class TableComponent<T> extends Vue {
	/**
	 * Cette propriété est soit la liste des éléments à afficher, soit une fonction retournant une Promise donnant la liste des éléments à afficher. Ceci est une Prop.
	 *
	 * @readonly
	 */
	@Prop({
		type: [Array, Function],
		default: (): T[] => [],
	})
	protected readonly data!: DataGetter<T> | T[];

	/**
	 * Liste des colonnes envoyées par l'utilisateurs.
	 */
	protected columns: Column[] = [];
	/**
	 * Liste des éléments à afficher.
	 */
	protected elements: T[] = [];
	/**
	 * Nombre total d'éléments disponible pour les filtres associés
	 */
	protected elementsTotal = 0;
	/**
	 * Liste des filtres à appliquer
	 */
	protected filters: Filter[] = [];
	/**
	 * Propriété permettant de savoir si l'on doit afficher les filtres.
	 */
	protected filtersShown = false;
	/**
	 * Nombre maximum d'éléments qu'on souhaite afficher.
	 */
	protected limit = 5;
	/**
	 * Page à partir de laquelle on veut afficher
	 */
	protected page = 1;
	/**
	 * Options envoyées au composant
	 *
	 * @readonly
	 */
	@Prop({
		default: (): Options => {
			return {};
		},
	})
	protected readonly options!: Options;
	/**
	 * Permet de savoir si le composant est en cours de chargement
	 */
	protected loading = false;
	/**
	 * Le texte qui a été saisi dans le champs de recherche
	 */
	protected search = "";

	/**
	 * Proriété permettant de savoir si l'on doit afficher le choix  du nombre de résultat à afficher. A éditer en utilisant {@link options}
	 *
	 * @readonly
	 * @default true
	 */
	protected get displayLengthShown() {
		if ("undefined" === typeof this.options.displayLengthShow) {
			return true;
		}
		return this.options.displayLengthShow;
	}

	/**
	 * Liste du nombre d'éléments à afficher. A éditer en utilisant {@link options}
	 *
	 * @readonly
	 * @default [5, 10, 20, 50]
	 */
	protected get displayLength() {
		return this.options.displayLength || [5, 10, 20, 50];
	}

	/**
	 * Permet de savoir si l'utilsateur souhaite un affichage sous forme de tableau. A éditer en utilisant {@link options}
	 *
	 * @readonly
	 * @default false
	 */
	protected get table() {
		if ("undefined" === typeof this.options.table) {
			return true;
		}
		return this.options.table;
	}

	/**
	 * Permet de savoir si l'utilisateur souhaite afficher la barre de recherche. A éditer en utilisant {@link options}
	 *
	 * @readonly
	 * @default true
	 */
	protected get searchShown() {
		if ("undefined" === typeof this.options.searchShow) {
			return true;
		}
		return this.options.searchShow;
	}

	/**
	 * Permet de savoir le nombre d'élément par ligne que l'utilisateur souhaite. A éditer en utilisant {@link options}
	 *
	 * @readonly
	 * @default 2
	 */
	protected get elementsPerLine() {
		return this.options.elementsPerLine || 2;
	}

	protected get searchDelay() {
		return this.options.searchDelay || 250;
	}

	// tslint:disable-next-line
	private dataGetter!: DataGetter<T>;
	private timeoutSearch: number | null = null;
	private draw = 0;

	private get refreshOnInit() {
		if ("undefined" === typeof this.options.refreshOnInit) {
			return true;
		}
		return this.options.refreshOnInit;
	}

	private get maxPage() {
		if (this.elementsTotal === 0) {
			return 1;
		}

		return Math.ceil(this.elementsTotal / this.limit);
	}

	private get pages() {
		const middle = 2;
		let start = this.page - middle;
		let stop = this.page + middle;
		if (this.page <= middle) {
			start = 1;
			stop = this.maxPage > 5 ? 5 : this.maxPage;
		} else if (this.page > this.maxPage - middle) {
			start = this.maxPage - 4;
			if (start < 1) {
				start = 1;
			}
			stop = this.maxPage;
		}
		const pages = [];
		for (let i = start; i <= stop; i++) {
			pages.push(i);
		}
		return pages;
	}

	/**
	 * Cette fonction permet de forcer le rafraîchissement du composant.
	 */
	public async refresh() {
		if (!this.loading) {
			this.draw++;

			const params = {
				draw: this.draw,
				page: this.page,
				length: this.limit,
				filter: [],
				order: this.columns
					.filter(
						(o: Column) =>
							"undefined" !== typeof o &&
							o.orderable === true &&
							"undefined" !== typeof o.dir
					)
					.map((order: Column) => {
						return order.data + "," + order.dir;
					}),
				search: this.search,
			} as Params;

			for (const filter of this.filters) {
				if (!filter.value || filter.value.length === 0) {
					continue;
				}

				let paramFilter = filter.data;

				if (filter.existence) {
					paramFilter += ",EXIST";
				} else if (!filter.exclude) {
					paramFilter += ",EQUAL";
				} else if (filter.exclude === true) {
					paramFilter += ",NOT_EQUAL";
				}

				if (filter.value instanceof Array) {
					paramFilter += "," + filter.value.join(",");
				} else {
					paramFilter += "," + filter.value;
				}

				params.filter.push(paramFilter);
			}

			this.loading = true;

			try {
				const response = await this.dataGetter(params);

				// if (response.draw === this.draw) {
				this.elementsTotal = response.total;
				this.elements = response.data;
				this.limit = response.perPage;
				// }
			} catch (e) {
				console.error(e);
			} finally {
				this.loading = false;
			}
		}
	}

	/**
	 * Cette fonction permet de change le sens du tri pour une colonne. Elle retire les anciens tris.
	 * Si aucun sens est fourni la rotation de tri sera : asc => desc => aucun => asc => etc..
	 *
	 * @param column Colonne concernée par la direction de tri
	 * @param order Sens du tri ou aucun
	 */
	protected changeOrder(column: Column, order: "asc" | "desc" | null = null) {
		if (!column.orderable) {
			return;
		}

		for (const columnOption of this.columns) {
			if (columnOption.data !== column.data) {
				columnOption.dir = undefined;
			}
		}

		if (order) {
			column.dir = order;
		} else {
			if (column.dir === "asc") {
				column.dir = "desc";
			} else if (column.dir === "desc") {
				column.dir = undefined;
			} else {
				column.dir = "asc";
			}
		}

		this.refresh();
	}

	protected created() {
		this.watchOptions();
		this.filtersShown = this.options.filtersShow || false;
		this.limit = this.options.displayLengthDefault || 5;

		if (!(this.data instanceof Array)) {
			this.dataGetter = this.data as DataGetter<T>;
		} else {
			this.dataGetter = (params: Params) => {
				return new Promise((resolve, reject) => {
					try {
						const start = Math.max(params.page - 1, 0) * params.length;

						resolve({
							draw: params.draw,
							total: this.data.length,
							data: (this.data as T[]).slice(start, start + params.length),
							perPage: params.length,
							currentPage: params.page,
						});
					} catch (e) {
						reject(e);
					}
				});
			};
		}

		if (this.refreshOnInit) {
			this.refresh();
		}
	}

	private getOrderClass(order: Column) {
		let orderClass = "sort";

		if (order.dir === "asc") {
			orderClass = "sort-up";
		} else if (order.dir === "desc") {
			orderClass = "sort-down";
		}

		return orderClass;
	}

	@Watch("options", { deep: true })
	private watchOptions() {
		if (!this.options) {
			console.warn("No option was given");
			return;
		}

		if (this.options.filters) {
			for (const filter of this.options.filters) {
				let filterDataList;
				const indexFilter = this.filters.findIndex(f => f.data === filter.data);

				if (indexFilter === -1) {
					filterDataList = { ...filter };
					this.filters.push(filterDataList);
				} else {
					filterDataList = this.filters[indexFilter];
					filterDataList.options = filter.options;
					filterDataList.value = filter.value;
				}
			}

			const indexToRemove = [];

			for (let i = 0; i < this.filters.length; i++) {
				const filter = this.filters[i];

				if (
					this.options.filters.findIndex(f => f.data === filter.data) === -1
				) {
					indexToRemove.push(i);
				}
			}

			indexToRemove.reverse().forEach(i => {
				this.filters.splice(i, 1);
			});
		}

		if (this.options.columns) {
			this.columns = [];

			for (const column of this.options.columns) {
				const columnIntern = {
					...column,
				};

				if ("string" === typeof columnIntern.dir) {
					if ("DESC" === columnIntern.dir.toUpperCase()) {
						columnIntern.dir = "desc";
					} else if ("ASC" === columnIntern.dir.toUpperCase()) {
						columnIntern.dir = "asc";
					}
				}

				this.columns.push(columnIntern);
			}
		}
	}

	@Watch("filters", { deep: true, immediate: false })
	private onInputFilter() {
		if (this.timeoutSearch) {
			window.clearTimeout(this.timeoutSearch);
		}

		this.timeoutSearch = window.setTimeout(() => {
			this.refresh();
		}, this.searchDelay);
	}

	@Watch("data")
	private onDataChange() {
		if ("function" !== typeof this.data) {
			this.refresh();
		}
	}

	@Watch("search")
	private onSearchChange() {
		if (this.timeoutSearch) {
			window.clearTimeout(this.timeoutSearch);
		}

		this.timeoutSearch = window.setTimeout(() => {
			this.page = 1;
			this.refresh();
		}, this.searchDelay);
	}

	@Watch("limit")
	private onLimitChange() {
		this.page = 1;
		this.refresh();
	}

	@Watch("page")
	private changePage(page: number) {
		if (page > 0 && page <= this.maxPage) {
			this.page = page;
			this.refresh();
		}
	}
}
