import { mathjs } from "@/libs/mathjs";

export type Gender = "MALE" | "FEMALE";

export type ViewType = "DETAILED" | "SIMPLE";

export enum StepsImc {
	MORBID_OBESITY = "imc > 40",
	SEVERE_OBESITY = "and(imc > 35, imc <= 40)",
	MODERATE_OBESITY = "and(imc > 30, imc <= 35)",
	OVERWEIGHT = "and(imc > 25, imc <= 30)",
	NORMAL_WEIGHT = "and(imc > 18.5, imc <= 25)",
	THIN = "and(imc > 16.5, imc <= 18.5)",
	FAMINE = "imc <= 16.5",
}

export class MorphologicalData {
	weight?: number;
	height?: number;
	gender?: Gender;

	get imc() {
		let imc: number | undefined = undefined;

		if (this.weight && this.height) {
			imc = this.weight / (this.height * this.height * 0.0001);
		}

		return imc;
	}

	get perfectWeight() {
		let perfectWeight: number | undefined = undefined;

		if (this.height) {
			perfectWeight = 0.89 * (this.height - 152.4);

			if (this.gender === "MALE") {
				perfectWeight += 49.9;
			} else if (this.gender === "FEMALE") {
				perfectWeight += 45.4;
			}
		}

		return perfectWeight;
	}

	get leanMass() {
		let leanMass = 0;

		if (!this.weight || !this.height || !this.imc) {
			return leanMass;
		}

		if (this.gender === "MALE") {
			leanMass = (9270 * this.weight) / (6680 + 216 * this.imc);
		} else if (this.gender === "FEMALE") {
			leanMass = (9270 * this.weight) / (8780 + 244 * this.imc);
		}

		return Math.max(leanMass, 0);
	}

	get stepImc() {
		let stepFound = undefined;

		for (const step in StepsImc) {
			// eslint-disable-next-line
			const formula = (StepsImc as any)[step] as string;

			if (mathjs.parse(formula).evaluate({ imc: this.imc })) {
				stepFound = step;
				break;
			}
		}

		return stepFound;
	}

	get realistWeight() {
		if (!this.imc || !this.weight) {
			return undefined;
		}

		let realistWeight: number | undefined = undefined;

		if (this.imc > 70) {
			realistWeight = this.weight - 70;
		} else if (this.imc > 50) {
			realistWeight = this.weight - 10;
		} else if (this.imc > 40) {
			realistWeight = this.weight - 5;
		} else if (this.imc) {
			realistWeight = this.weight;
		}

		return realistWeight;
	}
}

export interface CalculatedData {
	poids?: number;
	taille?: number;
	sexe?: Gender;
	poidsIdeal?: number;
	masseMaigre?: number;
	poidsRealiste?: number;
	imc?: number;
	doseJournalierePrescrite: number;
	poidsTheorique?: number;
	dosePoidsJournalierePrescrite?: number;
	doseJournaliereAjustee?: number;
	dosePoidsJournaliereAjustee?: number;
	ecartAbsoluPosologie?: number;
	dosePrescriteJournaliere70kg?: number;
	doseAjusteeJournaliere70kg?: number;
}
