import { axios } from "@/apis";
import { Bibliography } from "@/apis/models";
import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class IndexController extends Vue {
	private allBibliography: Bibliography[] | null = null;
	private search = "";

	private get bibliography() {
		if (!this.allBibliography) {
			return [];
		}

		if (this.search.trim().length === 0) {
			return this.allBibliography;
		}

		return this.allBibliography.filter(
			book =>
				IndexController.search(book.dci, this.search) ||
				(book.sources.length > 0 &&
					IndexController.search(book.sources.join(" "), this.search))
		);
	}

	private async created() {
		this.allBibliography = await axios
			.get("/treatments/sources")
			.then(response => response.data);
	}

	private static search(text: string, value: string) {
		return (
			text
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.search(
					new RegExp(
						`.*${value
							.normalize("NFD")
							.replace(/[\u0300-\u036f]/g, "")
							.trim()}.*`,
						"gi"
					)
				) != -1
		);
	}
}
