
import Vue from "vue";
import Component from "vue-class-component";

import { axios } from "@/apis";
import { PageMenu } from "./apis/models";

@Component
export default class AppController extends Vue {
	private toggleMenu = false;
	private pagesMenu: PageMenu[] = [];
	private loading = true;

	private async created() {
		this.loadPagesMenu();
		this.loading = false;

		this.$root.$on("update:menu", this.loadPagesMenu);
	}

	private async loadPagesMenu() {
		this.pagesMenu = (await axios.get("/pages/menu")).data as PageMenu[];
	}
}
