
import Vue from "vue";
import Component from "vue-class-component";

import { axios } from "@/apis";
import { Page } from "@/apis/models";

@Component
export default class IndexController extends Vue {
	private loading = true;
	private homePage: Page | null = null;

	private async created() {
		this.homePage = (await axios.get("/pages/home")).data as Page;
		this.loading = false;
	}
}
