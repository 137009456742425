var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-offset-3 is-half"},[_c('h1',{directives:[{name:"t",rawName:"v-t",value:('title'),expression:"'title'"}],staticClass:"title is-1"}),_c('step-morphological-data',{model:{value:(_vm.morphologicalData),callback:function ($$v) {_vm.morphologicalData=$$v},expression:"morphologicalData"}}),(
				_vm.morphologicalData.weight &&
					_vm.morphologicalData.height &&
					_vm.morphologicalData.imc < 30
			)?_c('article',{staticClass:"message is-success mt-5"},[_vm._m(0)]):_vm._e(),(
				_vm.morphologicalData.imc >= 30 &&
					_vm.morphologicalData.weight &&
					_vm.morphologicalData.height &&
					_vm.morphologicalData.gender
			)?[_c('step-treatment',{staticClass:"mt-5",model:{value:(_vm.treatment),callback:function ($$v) {_vm.treatment=$$v},expression:"treatment"}}),(_vm.treatment)?_c('step-calculate',{staticClass:"mt-5",attrs:{"morphological-data":_vm.morphologicalData,"treatment":_vm.treatment}}):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"message-body content"},[_c('p',[_vm._v(" Le patient est considéré comme obèse pour un IMC supérieur à 30 kg/m². Les formules d'adaptation posologique seront applicable uniquement dans ce cas ")])])}]

export { render, staticRenderFns }