import { create, all, MathJsStatic } from "mathjs";

const mathjs = create(all) as MathJsStatic;

// eslint-disable-next-line
const attentionFunction = (args: any[]) => {
	return args[0];
};

attentionFunction.rawArgs = true;

mathjs.import(
	{
		si: function(condition: boolean, thenValue: number, elseValue: number) {
			return condition ? thenValue : elseValue;
		},
		attention: attentionFunction,
	},
	{}
);

export { mathjs };
