
















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class MessageWarningDosageComponent extends Vue {
	@Prop()
	private readonly path!: string;

	@Prop()
	private readonly dosage!: string;

	@Prop()
	private readonly unit!: string;
}
