


























































import Vue from "vue";
import Component from "vue-class-component";
import { Model, Prop } from "vue-property-decorator";

import { AlertRecommendation } from "@/apis/models";

@Component
export default class AlertRecommendationController extends Vue {
	@Prop()
	private readonly value!: AlertRecommendation;

	@Model("input")
	private model?: AlertRecommendation | AlertRecommendation[];

	private get isActive() {
		if (this.model instanceof Array) {
			return this.model.indexOf(this.value) !== -1;
		}

		return this.model === this.value;
	}

	private onClick() {
		if (this.model instanceof Array) {
			const index = this.model.indexOf(this.value);

			if (index === -1) {
				this.model.push(this.value);
			} else {
				this.model.splice(index, 1);
			}
		} else if ("string" === typeof this.model) {
			if (this.model === this.value) {
				this.model = undefined;
			} else {
				this.model = this.value;
			}
		}
	}
}
