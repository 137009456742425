import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import {
	Input as InputComponent,
	Select as SelectComponent,
	Textarea as TextareaComponent,
} from "@/components/form";

import { axios } from "@/apis";
import { Page, PageParam } from "@/apis/models";
import { AxiosError } from "axios";

@Component({
	components: {
		InputComponent,
		SelectComponent,
		TextareaComponent,
	},
})
export default class AddEditController extends Vue {
	@Prop()
	private readonly id!: number;

	private params: PageParam = {
		draft: true,
		homeReplace: false,
		link: "",
		menuShow: false,
	};
	private loading = true;
	private page: Page | null = null;
	private linkSetManually = false;
	private errors: { [key: string]: string } = {};

	private get link() {
		return this.params.link;
	}

	private set link(value: string) {
		if (!value) {
			return;
		}

		this.params.link = value.split(" ").join("_");
	}

	private async created() {
		if (this.id) {
			this.page = (await axios.get(`/pages/${this.id}`)).data as Page;

			this.params = {
				...this.page,
			};
			this.linkSetManually = true;
		}

		this.loading = false;
	}

	@Watch("params.contentFr")
	private updateLink() {
		if (this.linkSetManually || !this.params.contentFr) {
			return;
		}

		const matches = this.params.contentFr.match(/<h1>(?<title>.*)<\/h1>/i);

		if (matches && matches[1].length > 0) {
			this.link = matches[1]
				.split(" ")
				.slice(0, 4)
				.join(" ");
		}
	}

	private async onSubmit() {
		try {
			const params = {
				...this.params,
			};
			this.errors = {};

			if (
				this.params.contentFr &&
				(this.params.contentFr.length === 0 ||
					this.params.contentFr === "<p></p>")
			) {
				params.contentFr = undefined;
			}

			if (
				this.params.contentEn &&
				(this.params.contentEn.length === 0 ||
					this.params.contentEn === "<p></p>")
			) {
				params.contentEn = undefined;
			}

			if (!this.page) {
				await axios.post("/pages", params);
			} else {
				await axios.put(`/pages/${this.page.id}`, params);
			}

			this.$root.$emit("update:menu");
			this.$router.push({ name: "admin.pages" });
		} catch (e) {
			const error = e as AxiosError;

			if (error.response) {
				this.errors = error.response.data;
			}
		}
	}
}
