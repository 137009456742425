import Component from "vue-class-component";
import { TextareaComponent as TextareaCommon } from "common-vuejs-form";

import { Editor } from "@/components/editor";

import { Field as FieldComponent } from "../";
import { Prop } from "vue-property-decorator";

@Component({
	components: {
		FieldComponent,
		Editor,
	},
})
export class TextareaComponent extends TextareaCommon {
	@Prop({
		default: false,
	})
	private readonly useEditor!: boolean;
}
