import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export class FieldComponent extends Vue {
	@Prop({
		default: "",
	})
	private readonly label!: string;

	@Prop({
		default: "",
	})
	private readonly for!: string;

	@Prop({
		default: false,
	})
	private readonly required!: boolean;

	@Prop({
		default: false,
	})
	private readonly horizontal!: boolean;

	@Prop({
		default: false,
	})
	private readonly addonsLeft!: boolean;

	@Prop({
		default: false,
	})
	private readonly addonsRight!: boolean;

	@Prop({
		default: false,
	})
	private readonly iconsLeft!: boolean;

	@Prop({
		default: false,
	})
	private readonly iconsRight!: boolean;

	@Prop({
		default: "",
	})
	private readonly error!: string;

	private errorMessage = "";

	private get id() {
		return this.for;
	}

	@Watch("error")
	private updateErrorMessage() {
		if (!this.error || this.error.length === 0) {
			this.errorMessage = "";
			return;
		}

		const matches = this.error.match(/^(?<name>.*)\((?<args>.*)\)$/);

		console.log(this.error, matches);
		if (!matches) {
			this.errorMessage = this.$t(`constraints.${this.error}`) as string;
			return;
		}

		this.errorMessage = this.$t(
			`constraints.${matches[1]}`,
			matches.slice(2)
		) as string;
	}
}
