import Vue from "vue";
import Component from "vue-class-component";

import { Input as InputComponent } from "@/components/form";

@Component({
	components: {
		InputComponent,
	},
})
export class IndexController extends Vue {
	protected username = "";
	protected password = "";
	protected authenticationFailed = false;

	private get from() {
		return this.$route.query.from as string;
	}

	private created() {
		if (!this.from) {
			this.$router.push({ name: "home" });
			return;
		}
	}

	private async onSubmit() {
		try {
			await this.$auth.authenticate(this.username, this.password);
			this.$router.push(this.from);
		} catch (e) {
			this.authenticationFailed = true;
		}
	}
}
