import { axios } from "@/apis";
import { Page } from "@/apis/models";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class IndexController extends Vue {
	@Prop()
	private readonly link!: string;

	private loading = true;
	private page: Page | null = null;

	private async created() {
		await this.loadPage();
	}

	@Watch("link")
	private async loadPage() {
		const id = parseInt(this.$route.query.id as string, 10);
		this.loading = true;

		try {
			if (this.$auth.authenticated && id) {
				this.page = (await axios.get(`/pages/${id}`)).data as Page;
			} else {
				this.page = (await axios.get(`/pages/${this.link}/view`)).data as Page;
			}
			this.loading = false;
		} catch (e) {
			console.log("error");
		}
	}
}
