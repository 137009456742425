import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import {
	Input as InputComponent,
	Select as SelectComponent,
} from "@/components/form";

import { MorphologicalData } from "../typings";

@Component({
	components: {
		InputComponent,
		SelectComponent,
	},
})
export class StepMorphologicalDataController extends Vue {
	@Prop({ required: true })
	private readonly value!: MorphologicalData;

	@Watch("value", { deep: true })
	private onWatchValue() {
		this.$emit("input", this.value);
	}
}
