import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AdminTreatments from "@/views/admin/treatments/vues/List.vue";
import AdminAddEditTreatments from "@/views/admin/treatments/vues/AddEdit.vue";

import AdminPages from "@/views/admin/pages/vues/List.vue";
import AdminAddEditPages from "@/views/admin/pages/vues/AddEdit.vue";

import Authentication from "@/views/admin/authentication/vues/Index.vue";

import Calculator from "@/views/public/calculator/vues/Index.vue";

import Home from "@/views/public/home/vues/Index.vue";

import Pages from "@/views/public/pages/vues/Index.vue";

import Bibliography from "@/views/public/bibliography/vues/Index.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: "/admin/treatments",
		name: "admin.treatments",
		component: AdminTreatments,
		meta: {
			secured: true,
		},
	},
	{
		path: "/admin/treatments/add",
		name: "admin.treatments.add",
		component: AdminAddEditTreatments,
		meta: {
			secured: true,
		},
	},
	{
		path: "/admin/treatments/:id",
		name: "admin.treatments.edit",
		component: AdminAddEditTreatments,
		props: true,
		meta: {
			secured: true,
		},
	},
	{
		path: "/admin/pages",
		name: "admin.pages",
		component: AdminPages,
		meta: {
			secured: true,
		},
	},
	{
		path: "/admin/pages/add",
		name: "admin.pages.add",
		component: AdminAddEditPages,
		props: true,
		meta: {
			secured: true,
		},
	},
	{
		path: "/admin/pages/:id/edit",
		name: "admin.pages.edit",
		component: AdminAddEditPages,
		props: true,
		meta: {
			secured: true,
		},
	},
	{
		path: "/admin/authentication",
		name: "admin.authentication",
		component: Authentication,
	},
	{
		path: "/calculator",
		name: "calculator",
		component: Calculator,
	},
	{
		path: "/home",
		name: "home",
		component: Home,
	},
	{
		path: "/pages/:link",
		name: "pages",
		component: Pages,
		props: true,
	},
	{
		path: "/bibliography",
		name: "bibliography",
		component: Bibliography,
	},
	{
		path: "/",
		redirect: "/calculator",
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

export default router;
