import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import { mathjs } from "@/libs/mathjs";
import {
	Field as FieldComponent,
	Select as SelectComponent,
	Input as InputComponent,
	Textarea as TextareaComponent,
} from "@/components/form";
import { AlertRecommendation as AlertRecommendationComponent } from "@/components/alert-recommendation";
import { axios } from "@/apis";
import {
	AlertRecommendation,
	ProcessOfAdministration,
	RouteOfAdministration,
	Treatment,
	TreatmentParam,
	Unit,
} from "@/apis/models";
import { AxiosError } from "axios";

@Component({
	components: {
		AlertRecommendationComponent,
		FieldComponent,
		SelectComponent,
		InputComponent,
		TextareaComponent,
	},
})
export class AddEditController extends Vue {
	@Prop()
	private readonly id!: number;

	private treatment: Treatment | null = null;
	private errors: { [key: string]: string } = {};
	private param: TreatmentParam = {
		pharmacologicalClass: "",
		dci: "",
		formulaDailyDose: "",
		formulaTheoricWeight: "",
		routeOfAdministration: RouteOfAdministration.ORAL,
		unit: Unit.MICROGRAM,
		alertRecommendation: [],
	};
	private pharmacologicalClasses: string[] = [];

	private created() {
		this.watchId();
	}

	private get optionsRouteOfAdministration() {
		return Object.keys(RouteOfAdministration);
	}

	private get optionsProcessOfAdministration() {
		return Object.keys(ProcessOfAdministration);
	}

	private get optionsUnit() {
		return Object.keys(Unit);
	}

	private get optionsAlertRecommendation() {
		return Object.keys(AlertRecommendation);
	}

	@Watch("id")
	private async watchId() {
		this.pharmacologicalClasses = (
			await axios.get("/treatments/pharmacologicalClasses")
		).data;

		if (this.id) {
			this.treatment = (await axios.get(`/treatments/${this.id}`)).data;

			if (this.treatment) {
				this.param = {
					...this.treatment,
					alertRecommendation: this.treatment.alertRecommendation || [],
				};
			}
		}
	}

	private async onSubmit(event: Event) {
		this.errors = {};

		this.checkFormula("formulaDailyDose", this.param.formulaDailyDose);
		this.checkFormula("formulaTheoricWeight", this.param.formulaTheoricWeight);

		if (Object.keys(this.errors).length > 0) {
			event.preventDefault();
			return;
		}

		try {
			this.errors = {};

			if (!this.treatment) {
				await axios.post("/treatments", this.param);
			} else {
				await axios.put(`/treatments/${this.treatment.id}`, this.param);
			}
			this.$router.push({ name: "admin.treatments" });
		} catch (e) {
			const error = e as AxiosError;

			if (error.response) {
				this.errors = error.response.data;
			}
		}
	}

	private checkFormula(errorKey: string, formula: string | undefined) {
		if (formula) {
			try {
				mathjs.parse(formula);
			} catch (e) {
				this.errors[errorKey] = "Formule incorrecte.";
			}
		}
	}
}
