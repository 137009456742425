import Component from "vue-class-component";
import { InputComponent as InputCommon } from "common-vuejs-form";

import { Field as FieldComponent } from "../";
import { Prop, Ref } from "vue-property-decorator";

@Component({
	components: {
		FieldComponent,
	},
})
export class InputComponent extends InputCommon {
	@Ref()
	private readonly input!: HTMLInputElement;

	@Prop({
		default: false,
	})
	private readonly addonsRight!: boolean;

	@Prop({
		default: false,
	})
	private readonly addonsLeft!: boolean;

	@Prop({
		default: false,
	})
	private readonly iconsLeft!: boolean;

	@Prop({
		default: false,
	})
	private readonly iconsRight!: boolean;

	@Prop({
		default: null,
	})
	private readonly list!: string;

	private get valueInput() {
		return this.value;
	}

	protected onValueInput(evt: Event) {
		if ((evt.target as HTMLInputElement).value.length === 0) {
			this.$emit("input", null);
			return;
		}

		if (this.input.checkValidity()) {
			this.onInput(evt);
		}
	}

	protected get countFiles() {
		if (this.type !== "file") {
			return null;
		}

		return this.value ? (this.value as FileList).length : 0;
	}
}
