import Vue from "vue";
import Component from "vue-class-component";

import { Table as TableComponent } from "@/components/table";
import { Treatment } from "@/apis/models";
import { axios } from "@/apis";
import {
	Options,
	Params as ParamsTableComponent,
} from "@/components/table/typings";
import { Ref } from "vue-property-decorator";

@Component({
	components: {
		TableComponent,
	},
})
export class ListController extends Vue {
	@Ref()
	private readonly table!: TableComponent<Treatment>;

	private options: Options = {
		table: true,
		columns: [
			{
				name: "ID",
			},
			{
				name: this.$t("treatment.pharmacological.class") as string,
				data: "pharmacological_class",
				orderable: true,
			},
			{
				name: this.$t("treatment.dci") as string,
				data: "dci",
				dir: "asc",
				orderable: true,
			},
			{
				name: this.$t("treatment.route.of.administration") as string,
			},
			{
				name: this.$t("treatment.process.of.administration") as string,
			},
			{
				name: this.$t("treatment.update.at") as string,
				orderable: true,
				data: "updated_at",
			},
			{ name: " " },
		],
	};

	private async onDelete(treatment: Treatment) {
		await axios.delete(`/treatments/${treatment.id}`);
	}

	private getData(params: ParamsTableComponent) {
		const queryParams = [`page=${params.page}`];

		if (params.search) {
			queryParams.push(`search=${params.search}`);
		}

		if (params.order && params.order.length > 0) {
			queryParams.push(`orders[]=${params.order}`);
		}

		return axios
			.get(`/treatments?${queryParams.join("&")}`)
			.then(response => response.data);
	}
}
