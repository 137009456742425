var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-4"},[(_vm.editor)?_c('div',[_c('div',{staticClass:"is-inline-block buttons my-0 has-addons"},[_c('button',{staticClass:"button is-default",attrs:{"data-tooltip":"Annuler"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.undo()
							.run()}}},[_c('fa',{attrs:{"icon":"undo"}})],1),_c('button',{staticClass:"button is-default",attrs:{"data-tooltip":"Rétablir"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.redo()
							.run()}}},[_c('fa',{attrs:{"icon":"redo"}})],1)]),_c('div',{staticClass:"is-inline-block buttons ml-2 my-0 has-addons"},[_c('div',{staticClass:"button is-default"},[_c('input',{ref:"inputColor",attrs:{"type":"color"},domProps:{"value":_vm.editor.getAttributes('textStyle').color},on:{"input":function($event){_vm.editor
								.chain()
								.focus()
								.setColor($event.target.value)
								.run()}}})]),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('bold') },attrs:{"data-tooltip":"Gras"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleBold()
							.run()}}},[_c('fa',{attrs:{"icon":"bold"}})],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('italic') },attrs:{"data-tooltip":"Italique"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleItalic()
							.run()}}},[_c('fa',{attrs:{"icon":"italic"}})],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('strike') },attrs:{"data-tooltip":"Rayé"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleStrike()
							.run()}}},[_c('fa',{attrs:{"icon":"strikethrough"}})],1)]),_c('div',{staticClass:"is-inline-block buttons my-0 has-addons ml-2"},[_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('bulletList') },attrs:{"data-tooltip":"Liste à puces"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleBulletList()
							.run()}}},[_c('fa',{attrs:{"icon":"list-ul"}})],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('orderedList') },attrs:{"data-tooltip":"Liste numérotée"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleOrderedList()
							.run()}}},[_c('fa',{attrs:{"icon":"list-ol"}})],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('blockquote') },attrs:{"data-tooltip":"Citation"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleBlockquote()
							.run()}}},[_c('fa',{attrs:{"icon":"quote-right"}})],1)]),_c('div',{staticClass:"is-inline-block buttons my-0 has-addons ml-2"},[_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('paragraph') },attrs:{"data-tooltip":"Paragraphe"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.setParagraph()
							.run()}}},[_c('fa',{attrs:{"icon":"paragraph"}})],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('heading', { level: 1 }) },attrs:{"data-tooltip":"Titre grand"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleHeading({ level: 1 })
							.run()}}},[_c('fa-layers',[_c('fa',{attrs:{"icon":"heading"}}),_c('fat',{staticClass:"has-text-weight-bold",attrs:{"transform":"shrink-5 down-6 right-11","value":"1"}})],1)],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('heading', { level: 2 }) },attrs:{"data-tooltip":"Titre moyen"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.toggleHeading({ level: 2 })
							.run()}}},[_c('fa-layers',[_c('fa',{attrs:{"icon":"heading"}}),_c('fat',{staticClass:"has-text-weight-bold",attrs:{"transform":"shrink-5 down-6 right-11","value":"2"}})],1)],1),_c('button',{staticClass:"button is-default",class:{ 'is-info': _vm.editor.isActive('link') },attrs:{"data-tooltip":"Lien"},on:{"click":function($event){$event.preventDefault();return _vm.setLink.apply(null, arguments)}}},[_c('fa',{attrs:{"icon":"link"}})],1),_c('button',{staticClass:"button is-default",attrs:{"data-tooltip":"Formule"},on:{"click":function($event){$event.preventDefault();_vm.editor
							.chain()
							.focus()
							.setFormula()
							.run()}}},[_c('fa',{attrs:{"icon":"subscript"}})],1)])]):_vm._e(),_c('editor-content',{staticClass:"content",attrs:{"editor":_vm.editor}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }