var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title is-1"},[_vm._v("Traitements")]),_c('div',[_c('router-link',{staticClass:"button is-outlined is-primary",attrs:{"to":{ name: 'admin.treatments.add' }}},[_c('span',{staticClass:"icon"},[_c('fa',{attrs:{"icon":"plus"}})],1),_c('span',{directives:[{name:"t",rawName:"v-t",value:('action.add'),expression:"'action.add'"}]})])],1),_c('table-component',{ref:"table",attrs:{"data":_vm.getData,"options":_vm.options},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('tr',[_c('th',{staticClass:"is-narrow"},[_vm._v("ID")]),_c('th',{directives:[{name:"t",rawName:"v-t",value:('treatment.pharmacological.class'),expression:"'treatment.pharmacological.class'"}]}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('treatment.dci'),expression:"'treatment.dci'"}]}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('treatment.route.of.administration'),expression:"'treatment.route.of.administration'"}]}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('treatment.process.of.administration'),expression:"'treatment.process.of.administration'"}]}),_c('th',{directives:[{name:"t",rawName:"v-t",value:('treatment.update.at'),expression:"'treatment.update.at'"}]}),_c('th')])]},proxy:true},{key:"default",fn:function(ref){
var element = ref.element;
return [_c('tr',[_c('td',[_vm._v(_vm._s(element.id))]),_c('td',[_vm._v(_vm._s(element.pharmacologicalClass))]),_c('td',[_vm._v(_vm._s(element.dci))]),_c('td',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!element.routeOfAdministration),expression:"!element.routeOfAdministration"}]},[_vm._v("-")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(element.routeOfAdministration),expression:"element.routeOfAdministration"},{name:"t",rawName:"v-t",value:(
							("treatment.route.of.administration." + (element.routeOfAdministration))
						),expression:"\n\t\t\t\t\t\t\t`treatment.route.of.administration.${element.routeOfAdministration}`\n\t\t\t\t\t\t"}]})]),_c('td',[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!element.processOfAdministration),expression:"!element.processOfAdministration"}]},[_vm._v("-")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(element.processOfAdministration),expression:"element.processOfAdministration"},{name:"t",rawName:"v-t",value:(
							("treatment.process.of.administration." + (element.processOfAdministration))
						),expression:"\n\t\t\t\t\t\t\t`treatment.process.of.administration.${element.processOfAdministration}`\n\t\t\t\t\t\t"}]})]),_c('td',[_c('span',{attrs:{"data-tooltip":_vm._f("date")(element.updatedAt || element.createdAt,'datetime')}},[_vm._v(" "+_vm._s(_vm._f("date")(element.updatedAt || element.createdAt,"friendly"))+" ")])]),_c('td',{staticClass:"is-narrow"},[_c('span',{staticClass:"buttons is-flex-wrap-nowrap"},[_c('router-link',{staticClass:"button is-default is-small",attrs:{"to":{
								name: 'admin.treatments.edit',
								params: { id: element.id },
							}}},[_c('span',{staticClass:"icon"},[_c('fa',{attrs:{"icon":"pencil-alt"}})],1),_c('span',{directives:[{name:"t",rawName:"v-t",value:('action.edit'),expression:"'action.edit'"}]})]),_vm._v("  "),_c('button',{staticClass:"button is-danger is-outlined is-small",on:{"click":function($event){return _vm.onDelete(element)}}},[_c('span',{staticClass:"icon"},[_c('fa',{attrs:{"icon":"trash"}})],1),_c('span',{directives:[{name:"t",rawName:"v-t",value:('action.delete'),expression:"'action.delete'"}]})])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }