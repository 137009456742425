import Vue from "vue";
import { NumberFormatOptions } from "vue-i18n";

function filter(value: number) {
	if (value == null || Number.isNaN(value)) {
		return "";
	}

	let options: NumberFormatOptions = {
		minimumFractionDigits: 1,
		maximumFractionDigits: 1,
	};

	if (value < 1) {
		options = {
			minimumSignificantDigits: 2,
			maximumSignificantDigits: 2,
		};
	}

	return value.toLocaleString("fr-fr", options);
}

export default {
	install() {
		Vue.filter("number", filter);
	},
};

export { filter };
